<template>
  <div class="content">
    <div>
      <img class="product_image" @click="clickImage(item.id)" :src="item.src" alt="" v-for="item in imgList" :key="item.id">
    </div>
    <div class="video_mask" v-if="showVideoMask" @touchmove.prevent>
      <div class="video_outer">
        <video ref=video :src="videoPlaySrc"></video>
        <div @click="palyVideo" class="play_btn" v-show="!isPlay">
          <img src="../assets/image/play.png" alt="">
        </div>
      </div>
      <img @click="closeVideoMask" class="close_btn" src="../assets/image/close(1).png" alt="">
    </div>
    <div class="frame" v-show="showFrameTost" @touchmove.prevent>
      <div class="frame_outer">
        <div class="box">
          <div class="close">
            <img @click="showFrameTost = false" src="../assets/image/close.png" alt="">
          </div>
          <span class="hint">您可以使用微信扫描以下二维码 <br> 与我们的工作人员取得联系</span>
          <div class="qr">
            <img src="../assets/image/newQr.png" alt="">
          </div>
          <span class="hint">或致电我们</span>
          <div class="about">
            <span class="about">王经理 - </span>
            <a class="phoneNum" href="tel:17602390088">17602390088</a>
            <!-- <span class="phoneNum">17602390088</span> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  data () {
    return {
      imgList: [],
      showVideoMask: false,
      showFrameTost: false,
      isPlay: false,
      videoPlaySrc: 'https://pangxieweijia.wxj360.com/video/waimai_720p_01.mp4'
    }
  },
  created () {
    const URL = 'https://pangxieweijia.wxj360.com/takeout/website/m_Number.jpg'
    for (let i = 1; i < 13; i++) {
      const obj = {}
      const REPLACE_NUMBER = i < 10 ? `0${i}` : i
      obj.src = URL.replace(/Number/, REPLACE_NUMBER)
      obj.id = i
      this.imgList.push(obj)
    }
  },
  methods: {
    palyVideo () {
      this.isPlay = !this.isPlay
      this.$refs.video.play()
    },
    closeVideoMask () {
      this.isPlay = false
      this.$refs.video.pause()
      this.showVideoMask = false
    },
    clickImage (id) {
      console.log(id)
      switch (id) {
        case 3:
          this.showVideoMask = true
          break
        case 5:
          window.open('http://show.wxj360.com/mixed-demo/#/vertical')
          break
        case 6:
          this.showFrameTost = true
          break
        case 9:
          this.showFrameTost = true
          break
      }
    }
  }
}
</script>

<style lang="scss">
body{
  margin: 0 !important;
}
.content {
  width: 100vw;
  .product_image {
    width: 100vw;
  }
  .video_mask {
    width: 100vw;
    height: 100vh;
    background-color: rgba($color: #000000, $alpha: 0.4);
    position: fixed;
    left: 0;
    top: 0;
    z-index: 9;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .video_outer {
      border-radius: 20px;
      border: 4px solid rgb(193, 193, 193);
      overflow: hidden;
      position: relative;
      width: 80vw;
      height: 144vw;
      video {
        width: 81vw;
        height: auto;
      }
      .play_btn {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        img {
          width: 50px;
          height: 50px;
        }
      }
    }
    .close_btn {
      width: 50px;
      height: 50px;
    }
  }
  .frame{
    position: fixed;
    top: 0;
    left: 0;
    background: #00000070;
    z-index: 9;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    .frame_outer {
      border-radius: 20px;
      background: -webkit-linear-gradient(#fa3199, #7818ff);
      background: -o-linear-gradient(#fa3199, #7818ff);
      background: -moz-linear-gradient(#fa3199, #7818ff);
      background: linear-gradient(#fa3199, #7818ff);
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 10px;
    }
    .box{
      background: #FFFFFF;
      border-radius: 20px;
      z-index: 300;
      text-align: center;
      display: flex;
      flex-direction: column;
      box-sizing: border-box;
      padding: 15px 20px 20px 20px;
      .hint{
        font-size: 14px;
        color: #999999;
        display: inline-block;
      }
      .about{
        display: inline-block;
        line-height: 24px;
        text-align: center;
        .about{
          display: inline-block;
          font-size: 16px;
          line-height: 24px;
        }
        .phoneNum{
          font-size: 24px;
          color: #fa3199;
        }
      }
      .qr{
        display: flex;
        justify-content: center;
        img{
          width: 250px;
          // height: 220px;
          // margin: 0 34.5px;
        }
      }
      .close{
        width: 100%;
        display: flex;
        flex-direction: row-reverse;
        img{
          margin: 0;
          width: 20px;
          height: 20px;
          cursor: pointer;
        }
      }
    }
  }
}
</style>
